import React from "react";
import { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import Unlock from "../../assets/Unlock";
import Loading from "../../assets/Loading";
import Link from "../Link";
import { swapCopyVariables } from "../../utils";

const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 25px 20px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const StyledInput = styled.input`
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  font-size: ${({ theme }) => theme.fontSize.normal};
  text-align: center;
  padding: 1px 2px;

  @media (min-width: 430px) {
    width: 40px;
    height: 40px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0 10px;
  input {
    margin: 0 3px;
  }
`;

const ResendWrapper = styled.div`
  margin-top: 10px;
  height: 20px;
`;

const Email = styled.span`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const NumberInput = ({ value, onChange, index, ...rest }) => {
  return (
    <StyledInput
      pattern="[0-9]*"
      maxlength="1"
      value={value}
      onChange={({ target }) => onChange(index, target.value)}
      id={`passcodeInput${index}`}
      {...rest}
    />
  );
};

const NumberBox = ({ onChange, onSubmit, loading }) => {
  const [triedValue, setTriedValue] = useState(null);
  const theme = useTheme();
  const [passcode, setPasscode] = useState({
    n0: "",
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n5: "",
  });

  useEffect(() => {
    const currentValue = Object.values(passcode).join("");
    onChange && onChange(currentValue);
  }, [passcode, onChange]);

  useEffect(() => {
    const currentValue = Object.values(passcode).join("");
    if (currentValue.length === 6 && triedValue !== currentValue) {
      onSubmit && onSubmit(currentValue);
      setTriedValue(currentValue);
    }
  }, [passcode, onSubmit, triedValue]);

  const handleChange = (index, value) => {
    if (value !== "" && !/^\d{1}$/.test(value)) {
      return;
    }
    const key = `n${index}`;
    setPasscode(p => ({
      ...p,
      [key]: value,
    }));
    if (value === "") {
      return;
    }
    if (index === 5) {
      return;
    }
    const currentValue = Object.values(passcode).join("");
    if (currentValue.length > 5) {
      return;
    }
    const nextIndex = index + 1;
    const nextElement = document.getElementById(`passcodeInput${nextIndex}`);
    if (nextElement) {
      nextElement.focus();
    }
  };

  const handlePaste = event => {
    const s = event.clipboardData.getData("Text");

    if (s && /^\d{6}$/.test(s)) {
      const pastedCode = {
        n0: s[0],
        n1: s[1],
        n2: s[2],
        n3: s[3],
        n4: s[4],
        n5: s[5],
      };

      setPasscode(pastedCode);
    }
  };

  return (
    <InputWrapper>
      {loading && onSubmit ? (
        <Loading outerColor={theme.colors.primaryBlue} innerColor="white" />
      ) : (
        [0, 1, 2, 3, 4, 5].map(i => {
          const key = `n${i}`;
          return (
            <NumberInput
              key={key}
              index={i}
              onPaste={handlePaste}
              onChange={handleChange}
              value={passcode[key]}
              disabled={loading}
            />
          );
        })
      )}
    </InputWrapper>
  );
};

const Passcode = ({
  obfuscatedEmail,
  onChange,
  onSubmit,
  resend,
  loading,
  enterPasscodeTitle = "",
  enterPasscodeBody,
  resendText,
  resendLinkText,
  unlockIconVisible = true,
}) => {
  const [resendingCode, setResendingCode] = useState(false);
  const theme = useTheme();
  const handleResend = async () => {
    setResendingCode(true);
    await resend();
    setResendingCode(false);
  };
  const ObfuscatedEmail = () => <Email>{obfuscatedEmail}</Email>;
  const ResendLink = () => <Link onClick={handleResend}>{resendLinkText}</Link>;
  return (
    <Wrapper>
      {unlockIconVisible && <Unlock />}
      {enterPasscodeTitle !== "" && <h2>{enterPasscodeTitle}</h2>}
      <div>
        {swapCopyVariables(enterPasscodeBody, { EMAIL: <ObfuscatedEmail /> })}
      </div>
      <NumberBox onChange={onChange} onSubmit={onSubmit} loading={loading} />
      <ResendWrapper>
        {resendingCode ? (
          <Loading outerColor={theme.colors.primaryBlue} innerColor="white" />
        ) : (
          swapCopyVariables(resendText, { RESEND: <ResendLink /> })
        )}
      </ResendWrapper>
    </Wrapper>
  );
};

export default Passcode;
