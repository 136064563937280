import React from "react";
import styled from "styled-components";
import Success from "../../assets/Success";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme, coloredText }) =>
    coloredText ? theme.colors.darkBlue : "inherit"};
  padding: 16px ${({ withIcon }) => (withIcon ? "26px" : "16px")};
  border-radius: 10px;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const Content = styled.div`
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const InfoBox = ({ children, coloredText = true, withIcon = true }) => {
  return (
    <Wrapper withIcon={withIcon} coloredText={coloredText}>
      {withIcon && <Success />}
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default InfoBox;
