import React from "react";
import { useTheme } from "styled-components";
import styled from "styled-components";

const Svg = styled.svg`
  animation-name: spin;
  animation-duration: 850ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Loading = ({
  innerColor,
  outerColor,
  size = 16,
  stroke = 4,
  ...rest
}) => {
  const theme = useTheme();
  const outerStroke = outerColor || "#FFFFFF";
  const innerStroke = innerColor || theme.colors.primaryBlue;

  return (
    <Svg
      viewBox="0 0 44 44"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill="none"
        stroke={innerStroke}
        strokeWidth={stroke}
        d="M2,22 a20,20 0 0,1 20,-20"
      />
      <path
        fill="none"
        stroke={outerStroke}
        strokeWidth={stroke}
        d="M2,22 a20,20 0 1,0 20,-20"
      />
    </Svg>
  );
};

export default Loading;
