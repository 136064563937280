import React from "react";

export const debounce = (fn, time) => {
  let timeout;

  return function () {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const isMobile = (() => {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false;
  }
  return (
    /Mobile/.test(navigator.userAgent) ||
    // iPadOS (iOS 13 on iPad):
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
})();

export const isWin = (() => {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false;
  }
  return /Win/i.test(navigator.userAgent);
})();

export const isUndefined = val => typeof val === "undefined";

export const isdef = val => !isUndefined(val);

export const checkElementOverlap = (elem1, elem2) => {
  const rect1 = elem1.getBoundingClientRect();
  const rect2 = elem2.getBoundingClientRect();
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
};

export const swapCopyVariables = (copyString, variables) => {
  if (!copyString) {
    return [];
  }

  const variableGroup = /\[([A-Z_\-0-9]+?)\]/;
  const varKeys = Object.keys(variables);
  const copyKeys = copyString.match(new RegExp(variableGroup, "g")) || [];
  const missingKeys = copyKeys.filter(k => !varKeys.find(v => `[${v}]` === k));
  const unexpectedKeys = varKeys.filter(k => !copyKeys.includes(`[${k}]`));

  if (missingKeys.length) {
    console.error(
      `Expected variables not found in copy "${copyString}": ${missingKeys.map(
        v => `\n\t${v}`,
      )}`,
    );
  }

  if (unexpectedKeys.length) {
    console.error(
      `Unexpected variables found in copy "${copyString}": ${unexpectedKeys.map(
        v => `\n\t[${v}]`,
      )}`,
    );
  }

  return copyString.split(variableGroup).map((str, index) => {
    if (varKeys.indexOf(str) >= 0) {
      return React.isValidElement(variables[str])
        ? React.cloneElement(variables[str], { key: index })
        : variables[str];
    }
    return str;
  }, []);
};

// Get the first letters of the first and last string in a name.
export const getInitials = name => {
  return name
    .trim()
    .split(" ")
    .filter(str => str.trim().length > 0)
    .map(str => str[0].toUpperCase())
    .filter((str, i, arr) => i === 0 || i === arr.length - 1)
    .join("");
};

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
