import React from "react";
import { useTheme } from "styled-components";

const Error = ({ color }) => {
  const theme = useTheme();
  const stroke = color || theme.colors.darkRed;

  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.99975V8.99975M10 12.9998H10.01M3.072 16.9998H16.928C18.468 16.9998 19.43 15.3328 18.66 13.9998L11.732 1.99975C10.962 0.66675 9.038 0.66675 8.268 1.99975L1.34 13.9998C0.570001 15.3328 1.532 16.9998 3.072 16.9998Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Error;
