import React from "react";
import styled from "styled-components";
import Error from "../../assets/Error";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.lightRed};
  color: ${({ theme }) => theme.colors.darkRed};
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1.4;
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const IconWrapper = styled.div`
  margin-top: 3px;
`;

const Content = styled.div`
  margin: 0 10px;
`;

const ErrorBox = ({ children }) => {
  return (
    <Wrapper>
      <IconWrapper>
        <Error />
      </IconWrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default ErrorBox;
